import { Controller } from '@hotwired/stimulus';
import FrameSource from '../utils/frame_source';

export default class extends Controller {
  static targets = ['search', 'clearButton', 'additionalParams'];

  static values = { query: String, searchParams: String };

  declare readonly searchTarget: HTMLInputElement;

  declare readonly clearButtonTarget: HTMLButtonElement;

  declare readonly additionalParamsTarget: HTMLDivElement;

  declare queryValue: string;

  declare searchParamsValue: string;

  declare hasQueryValue: boolean;

  private form = this.element as HTMLFormElement;

  private timeout: Timeout;

  private toggleClearButton() {
    this.clearButtonTarget.classList.toggle('hidden', !this.hasQueryValue);
  }

  private setQueryValue(value: string) {
    this.queryValue = value || undefined;
  }

  private handleAutoFocus() {
    const ransackParams = new URLSearchParams(window.location.search).get(
      `q[${this.searchParamsValue}]`,
    );

    if (ransackParams) {
      const { length } = this.searchTarget.value;
      this.searchTarget.focus();
      this.searchTarget.setSelectionRange(length, length);
    }
  }

  public handleInputChange({
    currentTarget: { value },
  }: ListenerEvent<HTMLInputElement>) {
    this.setQueryValue(value);
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const finishedTyping = this.queryValue === value;
      if (finishedTyping) this.submit();
    }, 1000);
  }

  public submit() {
    this.appendAdditionalParams();
    this.form.requestSubmit();
  }

  public clearSearch() {
    this.searchTarget.value = '';
    this.queryValue = undefined;
    this.submit();
  }

  public queryValueChanged() {
    this.toggleClearButton();
  }

  public connect() {
    this.setQueryValue(this.searchTarget.value);
    this.handleAutoFocus();
    this.handleEnterKeyPress();
  }

  private handleEnterKeyPress() {
    this.searchTarget.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  private appendAdditionalParams() {
    this.additionalParamsTarget.innerHTML = '';
    const searchParams = new FrameSource(this.frameTarget()).params;
    searchParams.delete(this.searchParamName());
    searchParams.forEach((value, key) => {
      this.additionalParamsTarget.innerHTML += `<input type="hidden" name="${key}" value="${value}" />`;
    });
  }

  private frameTarget() {
    return document.getElementById(this.form.getAttribute('data-turbo-frame'));
  }

  private searchParamName() {
    return `q[${this.searchParamsValue}]`;
  }
}
