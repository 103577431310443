class UrlWithParams {
  private readonly url: URL;

  private readonly params: URLSearchParams;

  constructor(url: string) {
    this.url = new URL(url, window.location.origin);
    this.params = new URLSearchParams(this.url.search);
  }

  public set(key: string, value: string): void {
    this.params.set(key, value);
  }

  public append(key: string, value: string): void {
    this.params.append(key, value);
  }

  public urlString(): string {
    return this.appendWithParams();
  }

  private appendWithParams(): string {
    this.url.search = '';
    return [this.url, this.params.toString()].join('?');
  }
}

export default UrlWithParams;
