import { Controller } from '@hotwired/stimulus';
import UrlWithParams from '../utils/url_with_params';

export default class extends Controller {
  declare readonly frameTarget: HTMLElement;

  static targets = ['frame'];

  originalUrlWithParams(): UrlWithParams {
    return new UrlWithParams(this.frameTarget.getAttribute("src") || window.location.href)
  }

  setParam({ currentTarget }: EventTarget & { currentTarget: HTMLElement }) {
    const targetData = currentTarget.dataset;
    const urlWithParams = new UrlWithParams(this.frameTarget.getAttribute("src") || window.location.href);
    urlWithParams.set(targetData.name, targetData.value);
    this.reload(urlWithParams.urlString());
  }

  reload(src?: string) {
    this.frameTarget.setAttribute("src", src);
  }
}