/**
 * "which", "charCode", and "keyCode" are all deprecated and the latest
 * suggestion is to use "key".
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */
const KEYBOARD_KEYS = { SPACE: ' ', TAB: 'Tab' };

/**
 * Types of events that can be used for listeners.
 */
const EVENT_TYPES = {
  INPUT: 'input',
  KEY_DOWN: 'keydown',
  MOUSE_DOWN: 'mousedown',
};

/**
 * Types of HTMLInputElements.
 */
const INPUT_TYPES = { CHECKBOX: 'checkbox' };

/**
 * HTML Attributes.
 */
const HTML_ATTRIBUTES = { HREF: 'href', TAB_INDEX: 'tabindex' };

export { EVENT_TYPES, HTML_ATTRIBUTES, INPUT_TYPES, KEYBOARD_KEYS };
