import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['contentType'];

  declare contentTypeTargets: SVGAElement[];

  connect() {
    this.contentTypeTargets.forEach((contentType) => {
      contentType.addEventListener("mouseover", (event) => this.darkenOtherSections(event));
      contentType.addEventListener("mouseout", () => this.normalizeSections());
    });
  }

  private darkenOtherSections(event: MouseEvent) {
    this.contentTypeTargets.forEach((item) => {
      if (item !== event.currentTarget) {
        item.setAttribute("filter", "url(#darken25)");
      }
    });
  }

  private normalizeSections() {
    this.contentTypeTargets.forEach((item) => {
      item.removeAttribute("filter");
    });
  }
}
