import { Controller } from '@hotwired/stimulus';
import UrlWithParams from '../utils/url_with_params';

export default class extends Controller {
  static targets = ['frame'];

  static values = { url: String };

  declare readonly frameTarget: HTMLElement;

  declare readonly urlValue: string;

  private urlWithParams: UrlWithParams;

  connect() {
    this.urlWithParams = new UrlWithParams(this.urlValue);
  }


  public handleInputChange({
    currentTarget: { name, value },
  }: ListenerEvent<FormControl>) {
    this.setParams(name, value);
  }

  public handleCheckboxChange({
    currentTarget: { name, checked },
  }: ListenerEvent<HTMLInputElement>) {
    this.setParams(name, String(checked));
  }

  public handleDispatchedChange(event: CustomEvent) {
    const { name, value } = event.detail;
    this.setParams(name, value);
  }

  private setParams(name: string, value: string) {
    this.urlWithParams.set(name, value);
    this.setSource();
  }

  private setSource() {
    this.frameTarget.setAttribute('src', this.urlWithParams.urlString());
  }
}
