import { Controller } from '@hotwired/stimulus';

import { api, toast } from '../utils';

export default class extends Controller {
  static targets = ['menu', 'mainMenu', 'subMenu', 'menuItem', 'subMenuItem', 'iconButton'];

  static values = { activeMenuUrl: String, activeSubMenuUrl: String, expand: Boolean };

  declare readonly menuItemTargets: HTMLAnchorElement[];

  declare readonly mainMenuTarget: HTMLElement;

  declare readonly menuTarget: HTMLElement;

  declare readonly iconButtonTarget: HTMLElement;

  declare readonly subMenuTarget: HTMLElement;

  declare readonly hasSubMenuTarget: boolean;

  declare readonly subMenuItemTargets: HTMLAnchorElement[];

  declare activeMenuUrlValue: string;

  declare activeSubMenuUrlValue: string;

  declare expandValue: boolean;

  private toast: ReturnType<typeof toast>;

  private setActiveItem(
    urlValue: string,
    menuItems: HTMLAnchorElement[],
    activeClassList: string[],
  ) {
    menuItems.forEach(({ classList, href }) => {
      const urlMatches = !!urlValue && href === urlValue;
      const pathnameMatches = !urlValue && window.location.href.includes(href);

      if (urlMatches || pathnameMatches) {
        return classList.add(...activeClassList);
      }
    });
  }

  private setActiveMainItem(
    urlValue: string,
    menuItems: HTMLAnchorElement[],
    activeClassList: string[],
  ) {
    menuItems.forEach(({ classList, href }) => {
      const urlMatches = !!urlValue && href.includes(urlValue);
      const pathnameMatches = !urlValue && window.location.href.includes(href);

      if (urlMatches || pathnameMatches) {
        return classList.add(...activeClassList);
      }
    });
  }

  connect() {
    this.menuTarget.addEventListener("mouseenter", () => this.onHover(true));
    this.menuTarget.addEventListener("mouseleave", () => this.onHover(false));

    this.setActiveNavItems();
    if (this.expandValue) { this.setMenuState(); }
  }

  setActiveNavItems() {
    const menuItemActiveClasses = ['bg-green-400', 'text-blue-900', 'font-bold'];
    const subMenuItemActiveClasses = ['text-blue-900', 'font-bold'];

    this.setActiveMainItem(
      this.activeMenuUrlValue,
      this.menuItemTargets,
      menuItemActiveClasses,
    );

    this.setActiveItem(
      this.activeSubMenuUrlValue,
      this.subMenuItemTargets,
      subMenuItemActiveClasses,
    );
  }

  toggleExpand() {
    this.expandValue = !this.expandValue;
    this.setMenuState();
    this.handleUserOptionUpdate();
  }

  setMenuState() {
    this.setMenuWidths();
    this.rotateIcon();
    this.toggleMenu();
  }

  rotateIcon() {
    this.iconButtonTarget.classList.toggle('rotate-180');
  }

  onHover(expand: boolean) {
    if (this.expandValue) return;
    if (!this.hasSubMenuTarget) { this.setMenuWidths(expand); }
    this.toggleMenu(expand);
  }

  toggleMenu(expand: boolean = this.expandValue) {
    this.toggleSubMenu(expand);
    this.toggleMainMenuText(expand);
  }

  setMenuWidths(expand: boolean = this.expandValue) {
    const widthClasses = ['min-w-[280px]', 'w-[280px]'];
    widthClasses.forEach((widthClass) => {
      this.menuTarget.classList.toggle(widthClass, expand);
    });
  }

  toggleSubMenu(expand: boolean) {
    if (!this.hasSubMenuTarget) return;

    this.subMenuTarget.classList.toggle('-translate-x-full', !expand);
    this.mainMenuTarget.classList.toggle('w-full', !expand);
  }

  toggleMainMenuText(expand: boolean) {
    if (this.hasSubMenuTarget) return;
    this.menuItemTargets.forEach((menuItem) => {
      menuItem.querySelector('span')?.classList.toggle('hidden', !expand);
    });
  }

  private handleUserOptionUpdate = async () => {
    const formData = new FormData();
    try {
      formData.append('expand_sidebar', (this.expandValue.toString()));
      await api.post('/user_options', {
        body: formData,
      });
    } catch (error) {
      this.toast.open({ message: error.message, type: 'danger' });
    }
  };
}
