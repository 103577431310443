import { Controller } from '@hotwired/stimulus';
import { FileSizeValidator } from '../utils/fileSizeValidator';

export default class extends Controller {
  static targets = ['input', 'browse', 'validationOutput', 'wrapper'];

  static values = { valid: true, maxFileSize: Number };

  declare validationMessage: string;

  declare readonly inputTarget: HTMLInputElement;

  declare readonly wrapperTarget: HTMLDivElement;

  declare readonly browseTarget: HTMLSpanElement;

  declare readonly validationOutputTarget: HTMLParagraphElement;

  declare fileSizeValidator: FileSizeValidator;

  declare validValue: boolean;

  declare maxFileSizeValue: number;

  declare submitButton: HTMLButtonElement;

  public connect() {
    this.submitButton = this.inputTarget.form.querySelector('button[type="submit"]');
  }

  private setValidValue() {
    this.validValue = this.inputTarget.checkValidity();
  }

  public handleClick() {
    this.inputTarget.click();
  }

  public handleInvalid(event: ListenerEvent) {
    event.preventDefault();
    this.validValue = false;
  }

  public handleInputChange(event: ListenerEvent<HTMLInputElement>) {
    const [file] = event.currentTarget.files;

    this.fileSizeValidator = new FileSizeValidator(file, this.maxFileSizeValue);
    if (this.fileSizeValidator.validate()) {
      this.validationMessage = this.fileSizeValidator.message();
      this.validValue = false;
    } else {
      this.setValidValue();
    }
    this.validValueChanged();
    this.submitButton.disabled = !this.validValue;
  }

  public validValueChanged() {
    const invalid = !this.validValue;
    this.browseTarget.classList.toggle('border-danger', invalid);
    this.wrapperTarget.classList.toggle('shadow-input', this.validValue);
    this.wrapperTarget.classList.toggle('shadow-danger', invalid);
    this.validationOutputTarget.innerHTML = this.validValue
      ? ''
      : this.getValidationMessage();
  }

  private getValidationMessage() {
    return this.validationMessage || this.inputTarget.validationMessage;
  }
}
