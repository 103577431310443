/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

export default class extends Controller {
  static outlets = ["modal"];

  static targets = ["calendar", "arrow"]

  static values = {
    activeView: { default: "grid", type: String },
    events: Array,
    start: String,
  }

  declare readonly modalOutlet: any;

  declare readonly startValue: string

  declare activeViewValue: string

  declare readonly calendarTarget: HTMLElement

  declare readonly arrowTargets: HTMLElement[]

  declare eventsValue: unknown[]

  declare calendarElement: any;

  connect() {
    this.calendarInstance().render();
  }

  calendarInstance() {
    return new Calendar(this.calendarTarget, {
      headerToolbar: false,
      ...this.calendarOptions(),
    });
  }

  activeViewValueChanged() {
    this.appendViewParams();
    this.setCalendarViewParam();
    this.resetCalendar();
  }

  resetCalendar() {
    if (this.activeViewValue === "grid") {
      setTimeout(() => {
        this.calendarInstance().render();
      }, 50);
    }
  }

  public setToggleView({ target }: EventTarget & { target: HTMLButtonElement }) {
    const value = target.dataset.value;
    this.activeViewValue = value;
  }

  private setCalendarViewParam() {
    const url = new URL(window.location.href);
    window.history.pushState({}, "", this.setViewParams(url));
  }

  public appendViewParams() {
    this.arrowTargets.forEach((arrow, index) => {
      const url = new URL(arrow.getAttribute("href") || "", window.location.origin);
      this.arrowTargets[index].setAttribute("href", this.setViewParams(url));
    });
  }

  private setViewParams(url: URL) {
    url.searchParams.set("view", this.activeViewValue);
    return url.toString();
  }

  private calendarOptions() {
    return {
      eventBorderColor: "transparent",
      eventClick: (info: any) => { this.openEventModal(info); },
      eventContent: (info: any) => ({ html: this.setTitle(info) }),
      events: this.eventsValue || [],
      initialDate: this.startValue,
      plugins: [dayGridPlugin],
    };
  }

  private setTitle(info: any) {
    if (info.event.extendedProps.required) {
      return `<i class="fa-regular fa-circle-star mr-2"></i><span>${info.event.title}</span>`;
    }
    return info.event.title;
  }

  private openEventModal(info: any) {
    info.jsEvent.preventDefault();
    if (!info.event.extendedProps.frameId) return;
    this.setModalContent(info);
    this.modalOutlet.openValue = true;
  }

  private setModalContent(info: any) {
    const frame = this.modalOutlet.contentTarget.getElementsByTagName("turbo-frame")[0];
    frame.setAttribute("src", this.modalSource());
    frame.setAttribute("id", info.event.extendedProps.frameId);
  }

  private modalSource() {
    const currentParams = window.location.search;
    return `${window.location}${currentParams}`;
  }
}
