import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['reload'];

  static values = { fallbackSrc: String };

  declare readonly reloadTarget: HTMLElement;

  declare fallbackSrcValue: string;

  reload() {
    const src = this.reloadTarget.getAttribute("src") || this.fallbackSrcValue;
    this.reloadTarget.setAttribute("src", "");
    this.reloadTarget.setAttribute("src", src);
  }
}
