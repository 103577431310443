import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'destroy'];

  declare readonly inputTarget: HTMLInputElement;

  declare readonly destroyTarget: HTMLInputElement;

  setDestroy() {
    if (this.inputTarget.value === "") {
      this.destroyTarget.value = "1";
    } else {
      this.destroyTarget.value = "0";
    }
  }
}
