import { INotyfNotificationOptions, Notyf } from 'notyf';
import { theme } from './theme';

type Options = Partial<INotyfNotificationOptions>;

export const toast = (options?: Options) =>
  new Notyf({
    dismissible: true,
    duration: 3000,
    position: { x: 'center', y: 'top' },
    types: [
      {
        background: '#FCE9EA',
        className: 'rounded border border-danger text-danger',
        icon: false,
        type: 'danger',
      },
      {
        background: theme.colors.success,
        className: 'rounded border border-white',
        icon: false,
        type: 'success',
      },
    ],
    ...options,
  });
