import { Controller } from '@hotwired/stimulus';

import { toast } from '../utils';

export default class extends Controller {
  static values = { intent: String, message: String, ttl: Number };

  declare ttlValue: number;

  declare messageValue: string;

  declare intentValue: 'danger' | 'success';

  private toast: ReturnType<typeof toast>;

  private setToast() {
    if (!!this.toast) return;
    this.toast = toast();
  }

  public ttlValueChanged() {
    this.setToast();
    this.toast.options.duration = this.ttlValue;
  }

  public connect() {
    this.setToast();
    this.toast.open({
      message: this.messageValue,
      type: this.intentValue,
    });
  }
}
