class Validation {
  public init() {
    const inputs = document.querySelectorAll("input, select, textarea");

    inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener("blur", () => {
        input.classList.toggle("field_with_errors", !input.checkValidity());
      });

      input.addEventListener(
        "invalid", () => {
          input.classList.add("field_with_errors");
        },
      );
    });
  }
}

export const validation = new Validation();
