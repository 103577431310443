import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  public connect() {
    this.element.querySelectorAll('a').forEach(anchor => {
      if (anchor.host === window.location.host) return;

      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer';
    });
  }
}
