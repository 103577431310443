import { Controller } from '@hotwired/stimulus';

import { KEYBOARD_KEYS } from '../constants';

export default class extends Controller {
  static targets = [
    'instructions',
    'county',
    'svgPath',
    'map',
    'list',
    'clearButton',
  ];

  static values = { countyId: String, selectedId: String };

  declare readonly instructionsTarget: HTMLElement;

  declare readonly mapTarget: HTMLElement;

  declare readonly clearButtonTarget: HTMLElement;

  declare readonly countyTargets: HTMLElement[];

  declare readonly listTargets: HTMLElement[];

  declare readonly svgPathTargets: SVGPathElement[];

  private timeout: Timeout;

  declare countyIdValue: string;

  declare readonly hasCountyIdValue: boolean;

  private selectedIdValue: string;

  declare readonly hasSelectedIdValue: boolean;

  private toggleClearButton() {
    this.clearButtonTarget.classList.toggle('hidden', !this.hasSelectedIdValue);
  }

  private toggleInstructions() {
    this.instructionsTarget.classList.toggle('hidden', this.hasCountyIdValue);
  }

  private highlightSelectedSvg() {
    this.svgPathTargets.forEach(
      ({ classList, dataset: { active, countyId } }) => {
        if (JSON.parse(active)) {
          classList.toggle('text-blue-900', countyId === this.selectedIdValue);
          classList.toggle('text-primary', countyId !== this.selectedIdValue);
        }
      },
    );
  }

  private makeListScrollable() {
    this.listTargets.forEach(
      ({ dataset: { countyId }, previousElementSibling, style }) => {
        if (countyId === this.countyIdValue) {
          const headerHeight = previousElementSibling.clientHeight;
          const mapHeight = this.mapTarget.offsetHeight;
          style.maxHeight = `${mapHeight - headerHeight}px`;
        }
      },
    );
  }

  private showCounty() {
    this.countyTargets.forEach(({ classList, dataset: { countyId } }) => {
      classList.toggle('hidden', countyId !== this.countyIdValue);
    });
  }

  public handleKeyDown = (event: ListenerEvent) => {
    if (event.key === KEYBOARD_KEYS.SPACE) {
      event.preventDefault();
      this.selectedIdValue = event.currentTarget.dataset.countyId;
    }
  };

  public handleSvgPathClick({ currentTarget: { dataset } }: ListenerEvent) {
    this.selectedIdValue = dataset.countyId;
  }

  public handleHoverOrFocus({ currentTarget: { dataset } }: ListenerEvent) {
    clearTimeout(this.timeout);
    this.countyIdValue = dataset.countyId;
  }

  public handleLeaveOrBlur() {
    this.timeout = setTimeout(() => {
      this.countyIdValue = this.selectedIdValue || undefined;
    });
  }

  public handleClearClick() {
    this.selectedIdValue = undefined;
    this.countyIdValue = undefined;
  }

  public countyIdValueChanged() {
    this.toggleInstructions();
    this.showCounty();
    this.makeListScrollable();
  }

  public selectedIdValueChanged() {
    this.highlightSelectedSvg();
    this.toggleInstructions();
    this.showCounty();
    this.toggleClearButton();
  }
}
