// Entry point for the build script in your package.json
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@rails/actiontext")

import "@hotwired/turbo-rails"
import "chartkick/chart.js"

import "./controllers"
import { focusOutlineManager, trixUtil, validation } from "./utils"

trixUtil.addSuperScriptListener()
focusOutlineManager.start()

document.documentElement.addEventListener("turbo:load", () => {
  validation.init()
})
