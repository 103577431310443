import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

import { toast } from '../utils/toast';
import { api } from '../utils/api';

export default class extends Controller {
  static targets = ['sequence', 'loader'];

  static values = {
    controllerName: { default: 'admin/sequences', type: String },
    id: Number,
    loading: Boolean,
    model: String,
  };

  declare readonly sequenceTargets: HTMLElement[];

  declare readonly loaderTarget: HTMLElement;

  declare readonly modelValue: string;

  declare loadingValue: boolean;

  declare idValue: number;

  declare controllerNameValue: string;

  private formData = new FormData();

  private toast: ReturnType<typeof toast>;

  public connect() {
    this.toast = toast();
    const tbody = this.element.querySelector('tbody');
    Sortable.create(tbody, {
      animation: 150,
      chosenClass: 'bg-gray-200',
      dragClass: '!bg-gray-200',
      forceFallback: true,
      ghostClass: 'opacity-50',
      group: 'shared',
      handle: '.sortable',
      onEnd: this.handleSortEnd,
    });
    this.updateIndices();
  }

  public loadingValueChanged() {
    const hidden = !this.loadingValue;
    const table = this.element.querySelector('table');
    this.loaderTarget.setAttribute('aria-hidden', String(hidden));
    this.loaderTarget.classList.toggle('hidden', hidden);
    table.setAttribute('aria-hidden', String(this.loadingValue));
    table.classList.toggle('hidden', this.loadingValue);
  }

  private updateIndices() {
    return this.sequenceTargets.forEach((target, index) => {
      target.lastElementChild.innerHTML = String(index + 1);
    });
  }

  private handleLoadingEnd() {
    this.loadingValue = false;
  }

  private handleSortEnd = async () => {
    this.loadingValue = true;
    try {
      const ids = this.sequenceTargets.map(target => target.dataset.id);
      this.formData.set("sequences", ids.join(','));
      await api.patch(`/${this.controllerNameValue}/${this.idValue}?model=${this.modelValue}`, {
        body: this.formData,
      });

      this.updateIndices();
      this.handleLoadingEnd();
      this.toast.open({ message: 'Reorder was successful', type: 'success' });
    } catch (error) {
      this.toast.open({ message: error.message, type: 'danger' });
      this.handleLoadingEnd();
    }
  };
}
