class Api {
  private get csrfToken() {
    const token = document.querySelector('meta[name=csrf-token]');
    if (!token) return;
    return token.getAttribute('content');
  }

  private errorMessage(response: Response) {
    return `Something went wrong: ${response.statusText}`;
  }

  public async get<Data>(url: string, options?: RequestInit): Promise<Data> {
    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
      },
      method: 'GET',
      ...options,
    }).then(response => {
      if (response.ok) return response.json();
      throw Error(this.errorMessage(response));
    });
  }

  public async patch(url: string, options?: RequestInit): Promise<string> {
    return fetch(url, {
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
      method: 'PATCH',
      ...options,
    }).then(response => {
      if (response.ok) return response.text();
      throw Error(this.errorMessage(response));
    });
  }

  public async post(url: string, options?: RequestInit): Promise<string> {
    return fetch(url, {
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
      method: 'POST',
      ...options,
    }).then(response => {
      if (response.ok) return response.text();
      throw Error(this.errorMessage(response));
    });
  }
}

export const api = new Api();
