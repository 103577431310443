import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'checkAll'];

  static values = { requireOne: Boolean };

  declare readonly checkboxTargets: HTMLInputElement[];

  declare readonly checkAllTarget: HTMLInputElement;

  declare readonly hasCheckAllTarget: boolean;

  declare requireOneValue: boolean;

  public handleInvalid(event: ListenerEvent) {
    event.preventDefault();
    this.requireOneValue = true;
  }

  public handleSelectAll(event: ListenerEvent<HTMLInputElement>) {
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.disabled) return;
      checkbox.checked = event.currentTarget.checked;
    });
  }

  public handleChange() {
    this.handleRequireOne();
    this.handleCheckAllTargetState();
  }

  public requireOneValueChanged() {
    this.handleRequireOne();
  }

  private handleRequireOne() {
    if (this.requireOneValue) {
      const validity = this.hasOne ? '' : 'Please choose at least one';
      this.checkboxTargets.forEach(checkbox => {
        checkbox.setCustomValidity(validity);
      });
    }
  }

  private get hasOne() {
    return this.checkboxTargets.some(({ checked }) => checked);
  }

  private handleCheckAllTargetState() {
    if (this.hasCheckAllTarget && this.checkAllTarget.checked !== this.allChecked) {
      this.checkAllTarget.checked = this.allChecked;
    }
  }

  private get allChecked() {
    return this.checkboxTargets.every(
      ({ checked, disabled }) => disabled || checked,
    );
  }
}
