import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  public appendAdditionalParams(event: MouseEvent) {
    const existingParams = new URLSearchParams(new URL(window.location.href).search);
    const anchor = event.target as HTMLAnchorElement;
    const newParams = new URLSearchParams(anchor.search);

    newParams.forEach((value, key) => {
      if (key.includes('[]')) {
        existingParams.append(key, value);
      } else {
        existingParams.set(key, value);
      }
    });

    anchor.setAttribute('href', this.createNewUrlWithParams(anchor, existingParams));
  }

  private createNewUrlWithParams(anchor: HTMLAnchorElement, params: URLSearchParams) {
    return `${anchor.origin}${anchor.pathname}?${params.toString()}`;
  }
}
