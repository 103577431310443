import { Controller } from '@hotwired/stimulus';
import { EVENT_TYPES } from '../constants';

export default class extends Controller {
  static targets = ['input'];

  declare readonly inputTargets: HTMLInputElement[];

  private get inputGiven() {
    return this.inputTargets.some(
      ({ checked, files, type, value }) =>
        (type !== 'checkbox' && value) ||
        (type === 'file' && files?.length) ||
        (type === 'checkbox' && checked),
    );
  }

  private disableInputs() {
    const { inputGiven } = this;
    this.inputTargets.forEach(input => {
      switch (input.type) {
        case 'file':
          return (input.disabled = inputGiven && !input.files.length);
        case 'checkbox':
          return (input.disabled = inputGiven && !input.checked);
        default:
          return (input.disabled = inputGiven && !input.value);
      }
    });
    return this.inputTargets;
  }

  private addListeners() {
    return this.inputTargets.forEach(input => {
      input.addEventListener(EVENT_TYPES.INPUT, () => this.disableInputs());
    });
  }

  public connect() {
    this.disableInputs();
    this.addListeners();
  }
}
