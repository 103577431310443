import { Controller } from '@hotwired/stimulus';
import { Instance } from 'tippy.js';
import { tippy } from '../utils';

export default class extends Controller {
  static values = {
    arrow: Boolean,
    content: String,
    placement: String,
    theme: String,
    trigger: String,
  };

  declare arrowValue: true;

  declare contentValue: string;

  declare placementValue: 'top';

  declare themeValue: string;

  declare triggerValue: 'mouseenter focus';

  private tippyInstance: Instance;

  private setTippyInstance() {
    if (!!this.tippyInstance) return;

    this.tippyInstance = tippy(this.element, {
      allowHTML: true,
      arrow: this.arrowValue || true,
      placement: this.placementValue || 'top',
      trigger: this.triggerValue || 'mouseenter focus',
    });
  }

  public arrowValueChanged() {
    this.setTippyInstance();
    this.tippyInstance.setProps({ arrow: this.arrowValue });
  }

  public contentValueChanged() {
    this.setTippyInstance();
    this.tippyInstance.setContent(this.contentValue);
  }

  public themeValueChanged() {
    this.setTippyInstance();
    this.tippyInstance.setProps({ theme: this.themeValue });
  }
}
