export class FileSizeValidator {
  declare file: File;

  declare limit: number;

  public constructor(file: File, limit: number) {
    this.file = file;
    this.limit = limit || 25000000;
  }

  public validate() {
    return this.file.size >= this.limit;
  }

  public message() {
    return `File size too big: ${this.readableFileSize(this.file.size)} (${this.readableFileSize(this.limit)} max).`;
  }

  private readableFileSize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1000));
    return `${(size / (1000 ** i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  }
}
