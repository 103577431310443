import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['collapse', 'content', 'icon'];

  static values = { open: Boolean, rotateOnHover: { default: false, type: Boolean } };

  declare readonly collapseTarget: HTMLDivElement;

  declare iconTarget: HTMLDivElement;

  declare readonly hasCollapseTarget: boolean;

  declare readonly hasIconTarget: boolean;

  declare readonly rotateOnHoverValue: boolean;

  declare readonly contentTarget: HTMLDivElement;

  declare openValue: boolean;

  private get contentHeight() {
    return `${this.contentTarget.offsetHeight}px`;
  }

  connect() {
    this.addHoverListeners();
  }

  private setCollapseHeight() {
    if (this.hasCollapseTarget) {
      this.collapseTarget.style.height = this.openValue
        ? this.contentHeight
        : '0';
    }
  }

  private addHoverListeners() {
    if (this.rotateOnHoverValue) {
      this.element.addEventListener('mouseenter', () => {
        this.open();
      });
      this.element.addEventListener('mouseleave', () => {
        this.close();
      });
    }
  }

  public toggleCollapse() {
    this.openValue = !this.openValue;
  }

  public close() {
    this.openValue = false;
  }

  public open() {
    this.openValue = true;
  }

  public openValueChanged() {
    this.setCollapseHeight();
    this.rotateIconToggleIcon();
  }

  public rotateIconToggleIcon() {
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle('rotate-180');
    }
  }
}
