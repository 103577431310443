import { Controller } from '@hotwired/stimulus';
import focusLock from 'dom-focus-lock';

export default class extends Controller {
  static targets = ['modal', 'content', 'overlay', 'wrapper'];

  static values = { open: Boolean };

  declare openValue: boolean;

  declare defaultContent: string;

  declare readonly modalTarget: HTMLElement;

  declare readonly wrapperTarget: HTMLElement;

  declare readonly overlayTarget: HTMLElement;

  declare readonly contentTarget: HTMLElement;

  connect() {
    this.defaultContent = this.contentTarget.innerHTML;
  }

  modalTargetDisconnected() {
    this.openValue = false;
  }

  public toggle(event: ListenerEvent<HTMLButtonElement>) {
    this.openValue = !this.openValue;
    this.setModalContents(event);
  }

  private setModalContents({
    currentTarget: {
      dataset: { modalContent },
    },
  }: ListenerEvent<HTMLButtonElement>) {
    if (modalContent !== undefined) {
      this.contentTarget.innerHTML = modalContent;
    }
  }

  public openValueChanged() {
    this.openValue ? this.open() : this.close();
  }

  public closeWithKeyboard(e: KeyboardEvent) {
    if (e.key === 'Escape' && this.openValue) {
      this.openValue = false;
    }
  }

  private open() {
    this.handleWrapperHidden();
    this.setFocusLock();
    setTimeout(() => {
      this.animateOverlay();
      this.animateModal();
    }, 300);
  }

  private close() {
    this.animateOverlay();
    this.animateModal();
    this.setFocusLock();
    setTimeout(() => {
      this.handleWrapperHidden();
      this.contentTarget.innerHTML = this.defaultContent;
    }, 300);
  }

  private setFocusLock() {
    focusLock[this.openValue ? 'on' : 'off'](this.wrapperTarget);
  }

  private handleWrapperHidden() {
    const hidden = !this.openValue;
    this.wrapperTarget.classList.toggle('hidden', hidden);
    this.wrapperTarget.setAttribute('aria-hidden', String(hidden));
  }

  private animateOverlay() {
    this.overlayTarget.classList.toggle('opacity-80', this.openValue);
  }

  private animateModal() {
    this.modalTarget.style.transform = this.openValue ? null : 'translateY(10%)';
    this.modalTarget.classList.toggle('opacity-0', !this.openValue);
  }
}
