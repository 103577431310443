  {
    "black": {
      "500": "#000000"
    },
    "white": {
      "500": "#ffffff"
    },
    "gray": {
      "50": "#fafafa",
      "100": "#eff0f3",
      "200": "#dcdde2",
      "300": "#d0d2d5",
      "400": "#79797f",
      "500": "#6a6a70",
      "600": "#5b5b5f",
      "700": "#4d4d51",
      "800": "#444446",
      "900": "#3c3b3e",
      "950": "#22272b"
    },
    "green": {
      "50": "#eaf1ef",
      "100": "#dcefe4",
      "200": "#bcdecd",
      "300": "#8fc6ae",
      "400": "#62bb94",
      "500": "#3e8b70",
      "600": "#2d715a",
      "700": "#235948",
      "800": "#1e473a",
      "900": "#193b31",
      "950": "#0d211b"
    },
    "blue": {
      "50": "#f4f6fb",
      "100": "#e8edf6",
      "200": "#ccd8eb",
      "300": "#a0b9d9",
      "400": "#6c93c4",
      "500": "#4a75ad",
      "600": "#385d91",
      "700": "#2e4a76",
      "800": "#294163",
      "900": "#223149",
      "950": "#1a2437"
    },
    "red": {
      "50": "#ffefef",
      "100": "#ffdcdc",
      "200": "#ffbfbf",
      "300": "#ff9292",
      "400": "#ff5454",
      "500": "#ff1f1f",
      "600": "#ff0000",
      "700": "#db0000",
      "800": "#b60000",
      "900": "#940808",
      "950": "#520000"
    },
    "pink": {
      "50": "#fef2f2",
      "100": "#fde4e3",
      "200": "#fccecc",
      "300": "#f9aba8",
      "400": "#f26f6a",
      "500": "#e9504a",
      "600": "#d5332d",
      "700": "#b32722",
      "800": "#942420",
      "900": "#7b2421",
      "950": "#430e0c"
    },
    "orange": {
      "50": "#fef6ee",
      "100": "#fdead7",
      "200": "#fbd0ad",
      "300": "#f8af79",
      "400": "#f48443",
      "500": "#f16522",
      "600": "#e24914",
      "700": "#bb3613",
      "800": "#952b17",
      "900": "#782716",
      "950": "#411009"
    }
  }
