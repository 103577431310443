/**
 * There is currently not enough typescript support for Trix, therefore, this
 *  will remain a js file for now.
 */

import Trix from "trix"

Trix.config.attachments.preview.caption = {
  name: false,
  size: false
}

Trix.config.textAttributes.sup = { inheritable: true, tagName: "sup" }

const handleInit = (event) => {
  const groupElement = event.target.toolbarElement.querySelector(
    ".trix-button-group.trix-button-group--text-tools"
  )

  const existingButton = groupElement.querySelector(
    "button[data-trix-attribute=sup]"
  )

  const superScriptButton =
    '<button type="button" class="trix-button" data-trix-attribute="sup" title="SUP" tabindex="-1">SUP</button>'

  return (
    !existingButton &&
    groupElement.insertAdjacentHTML("beforeend", superScriptButton)
  )
}

export const trixUtil = {
  addSuperScriptListener: () =>
    document.addEventListener("trix-initialize", handleInit)
}
