import { Controller } from '@hotwired/stimulus';

import { EVENT_TYPES, INPUT_TYPES } from '../constants';

const { INPUT } = EVENT_TYPES;
const { CHECKBOX } = INPUT_TYPES;

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation'];

  declare readonly passwordTarget: HTMLInputElement;

  declare readonly passwordConfirmationTarget: HTMLInputElement;

  declare readonly hasPasswordTarget: boolean;

  declare readonly hasPasswordConfirmationTarget: boolean;

  private requiredInputs: NodeList;

  private handlePasswordInputChange = () => {
    this.passwordTarget.setCustomValidity(
      this.passwordTarget.value === this.passwordConfirmationTarget.value
        ? ''
        : 'Passwords do not match',
    );
  };

  private addPasswordListeners() {
    if (this.hasPasswordTarget && this.hasPasswordConfirmationTarget) {
      this.passwordTarget.addEventListener(
        INPUT,
        this.handlePasswordInputChange,
      );
      this.passwordConfirmationTarget.addEventListener(
        INPUT,
        this.handlePasswordInputChange,
      );
    }
  }

  private get requirementsMet() {
    return Array.from(this.requiredInputs).every(
      ({ checked, type, value }: HTMLInputElement) =>
        (type === CHECKBOX && checked) || (type !== CHECKBOX && !!value),
    );
  }

  private handleRequiredInputChange = () => {
    const submit = this.element.querySelector('button[type="submit"]');
    submit.classList[this.requirementsMet ? 'remove' : 'add'](
      'opacity-50',
      'pointer-events-none',
    );
  };

  private addRequiredListeners() {
    this.requiredInputs.forEach(input => {
      input.addEventListener(INPUT, this.handleRequiredInputChange);
    });
  }

  public connect() {
    this.requiredInputs = this.element.querySelectorAll('[required]');
    this.addPasswordListeners();
    this.addRequiredListeners();
    this.handleRequiredInputChange();
  }
}
