import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["tab", "panel"];

  static values = { activeTab: String }

  declare readonly tabTargets: HTMLElement[];

  declare readonly panelTargets: HTMLElement[];

  declare activeTabValue: string;

  setActiveTab() {
    this.tabTargets.forEach((tab, index) => {
      if (tab.dataset.value === this.activeTabValue) {
        tab.dataset.active = "true";
        this.panelTargets[index].dataset.active = "true";
      } else {
        tab.dataset.active = "false";
        this.panelTargets[index].dataset.active = "false";
      }
    });
  }

  setTab({ currentTarget }: EventTarget & { currentTarget: HTMLElement }) {
    const value = currentTarget.dataset.value || this.tabTargets[0].dataset.value;
    this.activeTabValue = value;
  }

  activeTabValueChanged() {
    this.setActiveTab();
  }
}
