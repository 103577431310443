import { Controller } from "@hotwired/stimulus";
import { isNode } from "../utils";
import FrameSource from "../utils/frame_source";

export default class extends Controller {
  static classes = ["button", "buttonActive", "count", "countActive"]

  static targets = [
    "additionalParams",
    "badge",
    "box",
    "button",
    "selection",
    "count",
    "form",
    "wrapper",
  ]

  static values = { open: Boolean }

  declare readonly buttonClasses: string

  declare readonly buttonActiveClasses: string

  declare readonly countClasses: string

  declare readonly countActiveClasses: string

  declare readonly additionalParamsTarget: HTMLDivElement

  declare readonly badgeTarget: HTMLDivElement

  declare readonly boxTarget: HTMLDivElement

  declare readonly buttonTarget: HTMLButtonElement

  declare readonly selectionTargets: HTMLInputElement[]

  declare readonly countTarget: HTMLElement

  declare readonly formTarget: HTMLFormElement

  declare readonly wrapperTarget: HTMLDivElement

  declare openValue: boolean

  public initialize(): void {
    this.updateBadge();
  }

  public hide(e: MouseEvent) {
    if (this.shouldHideFilters(e.target)) {
      this.openValue = false;
      this.boxTarget.classList.add("hidden");
    }
  }

  public toggle() {
    this.openValue = !this.openValue;
    this.boxTarget.classList.toggle("hidden", !this.openValue);
  }

  public clear() {
    this.clearSelections();
    this.handleChange();
  }

  public handleChange() {
    this.appendAdditionalParams();
    this.submit();
  }

  public submit() {
    this.updateBadge();
    this.formTarget.requestSubmit();
  }

  public openValueChanged() {
    this.isActive() ? this.setActiveState() : this.setInActiveState();
  }

  private updateBadge() {
    this.countTarget.textContent = this.numberChecked();
    this.badgeTarget.classList.toggle("hidden", !this.hasFilters());
  }

  private isActive() {
    return !this.openValue && this.hasFilters();
  }

  private setActiveState() {
    this.buttonTarget.classList.add(...this.buttonActiveClasses);
    this.buttonTarget.classList.remove(...this.buttonClasses);
    this.countTarget.classList.add(...this.countActiveClasses);
    this.countTarget.classList.remove(...this.countClasses);
  }

  private setInActiveState() {
    this.buttonTarget.classList.remove(...this.buttonActiveClasses);
    this.buttonTarget.classList.add(...this.buttonClasses);
    this.countTarget.classList.remove(...this.countActiveClasses);
    this.countTarget.classList.add(...this.countClasses);
  }

  private shouldHideFilters(target: EventTarget) {
    isNode(target);
    return !this.wrapperTarget.contains(target) || target === this.wrapperTarget;
  }

  private appendAdditionalParams() {
    this.additionalParamsTarget.innerHTML = '';
    const searchParams = new FrameSource(this.frameTarget()).params;
    searchParams.delete(this.searchParamName());
    searchParams.forEach((value, key) => {
      this.additionalParamsTarget.innerHTML += `<input type="hidden" name="${key}" value="${value}" />`;
    });
  }

  private frameTarget() {
    return document.getElementById(this.formTarget.getAttribute('data-turbo-frame'));
  }

  private searchParamName() {
    return this.selectionTargets[0].name;
  }

  private clearSelections() {
    this.selectionTargets.forEach((c) => c.checked = false);
  }

  private hasFilters() {
    return this.selectionTargets.some((c) => c.checked);
  }

  private numberChecked() {
    return this.selectionTargets.filter((c) => c.checked).length.toString();
  }
}
