class FrameSource {
  declare frameElement: HTMLElement | null;

  constructor(frameElement: HTMLElement | null) {
    this.frameElement = frameElement;
  }

  get source() {
    return this.frameElement?.getAttribute("src");
  }

  get url() {
    return new URL(this.source || window.location.href);
  }

  get params() {
    return new URLSearchParams(this.url.search);
  }
}

export default FrameSource;