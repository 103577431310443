import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output'];

  static values = { total: String };

  declare readonly outputTarget: HTMLElement;

  declare totalValue: string;

  private startTime: number;

  private previousTimestamp: number;

  public connect() {
    this.observer.observe(this.element);
  }

  private get observer() {
    return new IntersectionObserver(
      ([entry], observer) => {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        requestAnimationFrame(this.increaseCount);
      },
      { threshold: [0] },
    );
  }

  private increaseCount = (timestamp: number) => {
    this.setInitialStartTime(timestamp);
    const elapsedTime = timestamp - this.startTime;
    const currentTimePosition = Math.min(elapsedTime / 800, 1);
    const countedToTotal =
      this.previousTimestamp === timestamp && currentTimePosition === 1;

    if (countedToTotal) return;

    this.previousTimestamp = timestamp;
    this.animate(currentTimePosition);
  };

  private animate(currentTimePosition: number) {
    this.setWholeNumber(Math.floor(currentTimePosition * this.numbers.whole));
    this.setDecimal(Math.floor(currentTimePosition * this.numbers.decimal));
    requestAnimationFrame(this.increaseCount);
  }

  private setWholeNumber(value: number) {
    this.outputTarget.innerHTML = String(value);
  }

  private setDecimal(value: number) {
    if (!value) return;
    this.outputTarget.innerHTML += `.${value}`;
  }

  private setInitialStartTime(time: number) {
    if (this.startTime === undefined) this.startTime = time;
  }

  private get numbers() {
    const [whole, decimal] = this.totalValue.split('.');
    return { decimal: Number(decimal), whole: Number(whole) };
  }
}
