import { EVENT_TYPES, KEYBOARD_KEYS } from '../constants';

const { KEY_DOWN, MOUSE_DOWN } = EVENT_TYPES;

class FocusOutlineManager {
  private container: HTMLElement;

  constructor() {
    this.container = document.documentElement;
  }

  /**
   * Toggles the focus-disabled class on the HTML element.
   */
  private toggleFocusDisabledClass(disabled?: boolean) {
    this.container.classList.toggle('focus-disabled', disabled);
  }

  /**
   * Listens for the tab key being pressed, turns focus outline management off,
   * and adds the mouse down listener back.
   */
  private handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === KEYBOARD_KEYS.TAB) {
      this.reset();
      this.container.addEventListener(MOUSE_DOWN, this.handleMouseDown);
    }
  };

  /**
   * Resets the container, set focus disabled class, and adds the tab key
   * listener to the container.
   */
  private handleMouseDown = () => {
    this.reset();
    this.toggleFocusDisabledClass(true);
    this.container.addEventListener(KEY_DOWN, this.handleKeyDown);
  };

  /**
   * Starts focus outline management.
   */
  public start() {
    this.container.addEventListener(MOUSE_DOWN, this.handleMouseDown);
  }

  /**
   * Stops focus outline management.
   */
  public stop() {
    this.reset();
  }

  /**
   * Removes all event listeners and classes that have been applied.
   */
  public reset() {
    this.toggleFocusDisabledClass(false);
    this.container.removeEventListener(KEY_DOWN, this.handleKeyDown);
    this.container.removeEventListener(MOUSE_DOWN, this.handleMouseDown);
  }
}

export const focusOutlineManager = new FocusOutlineManager();
