import SearchableInputController from "./searchable_input_controller";

export default class extends SearchableInputController {
  declare chosenItems: SearchableItem[];

  connect() {
    this.chosenItems = [];
    super.connect();
  }

  protected commit(selected: Element) {
    if (selected.getAttribute("data-chosen")) {
      const chosen_index = this.chosenItems.findIndex(item => item.value.toString() === selected.getAttribute("data-search-input-value"));
      this.chosenItems.splice(chosen_index, 1);
      this.destroyHiddenInput(selected);
    } else {
      this.chosenItems.push(
        this.collectionValue.find(item => item.value.toString() === selected.getAttribute("data-search-input-value")),
      );
      this.createHiddenInput(selected);
    }

    this.updateText();
    super.filterResults();
  }

  private elementId(element: Element) {
    return `${this.inputTarget.id}_${element.getAttribute("data-search-input-value")}`;
  }

  private createHiddenInput(selected: Element) {
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = this.hiddenTarget.name;
    hiddenInput.value = selected.getAttribute("data-search-input-value");
    hiddenInput.id = this.elementId(selected);
    this.hiddenTarget.insertAdjacentElement("afterend", hiddenInput);
  }

  private destroyHiddenInput(selected: Element) {
    const hiddenInput = document.getElementById(this.elementId(selected));
    hiddenInput.remove();
  }

  private updateText() {
    const textValue =
      this.collectionValue.filter(item => this.chosenItems.map(i => i.value).includes(item.value));
    if (textValue.length === 0) {
      this.inputTarget.value = "";
    } else {
      const extra = textValue.length > 1 ? ` + ${textValue.length - 1} more` : "";
      this.inputTarget.value = `${textValue[0].text}${extra}`;
    }
  }

  protected populateResults(collection: SearchableItem[]) {
    const chosen: SearchableItem[] = [];
    const unchosen: SearchableItem[] = [];
    collection.forEach(item => {
      if (this.chosenItems.map(i => i.value).includes(item.value)) {
        chosen.push(item);
      } else {
        unchosen.push(item);
      }
    });

    this.resultsTarget.innerHTML =
      chosen.map(item => `<li data-search-input-value="${item.value}" role="option" data-chosen="true" class="py-2 px-4 hover:bg-gray-200 cursor-pointer"> <span class="fa-regular fa-check mr-2"></span>${item.text}</li>`).join('');
    this.resultsTarget.insertAdjacentHTML('beforeend', '<hr class="first:hidden last:hidden">');
    this.resultsTarget.insertAdjacentHTML('beforeend',
      unchosen.map(item => `<li data-search-input-value="${item.value}" role="option" class="py-2 px-4 hover:bg-gray-200 cursor-pointer">${item.text}</li>`).join(''),
    );
  }

  onTabKeydown = () => {
    this.close();
  }

  onResultsClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest('[role="option"]');
    if (selected) {
      this.commit(selected);
      this.searchTarget.focus();
    }
  }

  onResultsMouseDown = () => {
    this.isMouseDown = true;
    this.resultsTarget.addEventListener("mouseup", () => {
      this.isMouseDown = false;
    });
  }
}
