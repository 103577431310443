import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textarea', 'output'];

  static values = { count: Number };

  declare readonly textareaTarget: HTMLTextAreaElement;

  declare readonly outputTarget: HTMLElement;

  declare countValue: number;

  public countValueChanged() {
    this.outputTarget.innerHTML = String(this.countValue);
  }

  public handleChange(event: ListenerEvent<HTMLTextAreaElement>) {
    this.countValue = event.currentTarget.value.length;
  }

  public connect() {
    this.countValue = this.textareaTarget.value?.length;
  }
}
